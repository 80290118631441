<template>
  <div class="user-details py-8">
    <UserAppbar />

    <div class="ma-4">
      <ViewingTitle :title="displayName" />
      <DeleteOrRestore
        v-if="hasDeletePermission"
        :model="user"
        :restore="restoreUser"
        :has-restore="true"
        name="user"
        redirect="/users"
      />

      <div class="d-flex mb-6">
        <v-tabs hide-slider class="user-info-tabs">
          <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">
            {{ item.tab }}
          </v-tab>

          <v-tab-item class="col-sm-12 col-xs-12 mt-6 pa-0">
            <avatar-form
              :user="user"
              :disabled="(user && !user.isUpdatable) || !hasUpdatePermission"
              :upload="changeAvatar"
              @remove="userAvatarRemoved"
            />

            <v-divider class="user-details__divider"></v-divider>

            <UserForm :user="user" />
          </v-tab-item>
        </v-tabs>
      </div>
    </div>

    <Loading :value="loading" />
  </div>
</template>

<script>
/**
 * ==================================================================================
 * View User
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import ViewingTitle from '@/components/common/ViewingTitle'
import AvatarForm from '@/components/common/AvatarForm'
import UserForm from '@/views/Home/Users/components/UserForm'
import UserAppbar from '@/views/Home/Users/components/UserAppbar'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  name: 'UserDetails',

  components: {
    ViewingTitle,
    AvatarForm,
    UserAppbar,
    UserForm,
    Loading,
    DeleteOrRestore,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
      tabItems: [{ tab: 'Info' }],
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.user.userDetails,
      permissions: (state) => state.auth.permissions,
    }),

    ...mapGetters({
      loggedInUserId: 'auth/userId',
    }),

    displayName() {
      return this.user ? this.user.full_name || this.user.email : null
    },

    isDisabled() {
      return this.user
        ? !!this.user.blocked_at || !!this.user.deleted_at
        : false
    },

    isOwner() {
      return this.$route.params.id
        ? this.loggedInUserId === parseInt(this.$route.params.id)
        : false
    },

    hasDeletePermission() {
      return validatePermissions([PERMISSION.USERS_DELETE], this.permissions)
    },

    hasUpdatePermission() {
      return validatePermissions([PERMISSION.USERS_UPDATE], this.permissions)
    },

    getDetail() {
      return this.isOwner ? this.getProfile : this.getUserDetails
    },
  },

  methods: {
    ...mapActions({
      getUserDetails: 'user/getUserDetails',
      changeAvatar: 'user/changeAvatar',
      restoreUser: 'user/restoreUser',
      getProfile: 'user/getProfile',
    }),

    ...mapMutations({
      clearUserDetails: 'user/clearUserDetails',
      userAvatarRemoved: 'user/userAvatarRemoved',
    }),

    async getUser() {
      this.loading = true
      await this.getDetail(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {
    this.getUser()
  },

  destroyed() {
    this.clearUserDetails()
  },

  watch: {
    $route() {
      this.getUser()
    },
  },
}
</script>

<style lang="scss" scoped>
.user-details {
  &__divider {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
